import { css } from '@emotion/react';

import black_6th_Background from '../../images/6th/black_6th_Background.jpg';
import black_6th_Background2x from '../../images/6th/black_6th_Background@2x.jpg';

import black_6th_Background_webp from '../../images/6th/black_6th_Background.webp';
import black_6th_Background2x_webp from '../../images/6th/black_6th_Background@2x.webp';

export const bgPattern6th = css`
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  background-image: url(${black_6th_Background});
  background-image: image-set(url(${black_6th_Background}) 1x, url(${black_6th_Background2x}) 2x);
  background-image: -webkit-image-set(url(${black_6th_Background_webp}) 1x, url(${black_6th_Background2x_webp}) 2x);
`;
